import workday from "../images/hiring/sources/workday.png";
import greenhouse from "../images/hiring/sources/greenhouse.png";
import rippling from "../images/hiring/sources/rippling.png";
import mock from "../images/hiring/sources/mock.png";
import lever from "../images/hiring/sources/lever.png";

export const HIRING_SOURCE_NAMES = {
  Lever: "lever",
  Greenhouse: "greenhouse",
};

export const CONNECTED_LABEL = "Connected";
export const DISCONNECTED_LABEL = "Disconnected";

export const HIRING_SOURCES = [
  {
    label: "Mock",
    value: "mock",
    benefits: ["Mock data", "Any amount of data", "Perfect Distribution"],
    setupTime: "1 minute",
    status: "Connected",
    logo: mock,
  },
  {
    label: "Lever",
    value: HIRING_SOURCE_NAMES.Lever,
    isDisabled: false,
    benefits: [
      "View all your employees from Lever",
      "Support Lever data visualizer",
      "Easy to integrate",
    ],
    setupTime: "2 minutes",
    status: "Disconnected",
    logo: lever,
  },
  {
    label: "Greenhouse",
    value: "greenhouse",
    isDisabled: false,
    benefits: [
      "View all your employees from Greenhouse",
      "Support Greenhouse data visualizer",
      "Easy to integrate",
    ],
    setupTime: "2 minutes",
    status: "Disconnected",
    logo: greenhouse,
  },
  {
    label: "Workday",
    value: "Workday",
    fetchSource: "",
    isDisabled: true,
    benefits: [
      "All your current employees",
      "Workdays data feature",
      "Easy to integrate",
    ],
    setupTime: "30 seconds",
    status: "Coming soon",
    logo: workday,
  },
  {
    label: "Rippling",
    value: "Rippling",
    isDisabled: true,
    benefits: ["New"],
    setupTime: "10 seconds",
    status: "Disconnected",
    logo: rippling,
  },
];
