import log from "loglevel";

export const initLogging = () => {
  log.setDefaultLevel(
    process.env.NEXT_PUBLIC_DEBUG_MODE === "true" ? "trace" : "error"
  );
};

export const LogDebug = (...msg) => {
  log.debug(...msg);
};
