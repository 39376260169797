/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore as getFirestoreFactory } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.GATSBY_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.GATSBY_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_PUBLIC_APP_ID,
  measurementId: process.env.GATSBY_PUBLIC_MEASUREMENT_ID,
};
let app;
let auth;

if (typeof window !== "undefined") {
  app = app || initializeApp(firebaseConfig);
  auth = getAuth(app);
}

// Instead of using the default getFirestore we will remap it such that
// we can use a preprod firestore database.
const getFirestore = () =>
  getFirestoreFactory(process.env.GATSBY_FIRESTORE_DATABASE_NAME);

export { auth, onAuthStateChanged, getFirestore };
