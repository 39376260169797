import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  serverTimestamp,
  query,
  where,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { IntegrationStatus } from "../../integrations/IntegrationConsts";
import { getFirestore } from "../../app/firebase";
import { HIRING_SOURCE_NAMES } from "../../consts/HiringSourceInformation";

// integrations will contain:
const initialState = {
  integrations: {},
};

const integrationSlice = createSlice({
  name: "integrationSlice",
  initialState,
  reducers: {
    setIntegrations(state, action) {
      LogDebug(
        setIntegrations.name,
        "Initiated setting integrations state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setIntegrationByName(state, action) {
      LogDebug(
        setIntegrationByName.name,
        "Initiated setting integrations state after loading.",
        action.payload
      );
      Object.assign(state, {
        integrations: {
          ...action.payload,
        },
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setLoading.name,
        "Initiated setting integrations data in loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

export const fetchAllIntegrations = () => {
  return async (dispatch, getState) => {
    const integrationNames = [
      HIRING_SOURCE_NAMES.Lever,
      HIRING_SOURCE_NAMES.Greenhouse,
    ];

    const integrations = {};

    for (const integrationName of integrationNames) {
      const state = getState();
      const user = state.user;
      let companyId = undefined;

      if (user && user.employer && user.employer.employerId) {
        companyId = user.employer.employerId;
      } else {
        throw new Error(
          "User's employer account ID not found. Cannot create new entry."
        );
      }

      dispatch(integrationSlice.actions.setLoading());

      const db = getFirestore();

      const integrationCollectionRef = collection(db, "integrations");
      const integrationsQ = query(
        integrationCollectionRef,
        where("integrationOwnerId", "==", companyId),
        where("integrationName", "==", integrationName)
      );

      const snapshot = await getDocs(integrationsQ);

      if (!snapshot.empty && snapshot.size === 1) {
        const document = snapshot.docs[0];
        const data = document.data();
        integrations[integrationName] = data;
      }
    }
    dispatch(
      integrationSlice.actions.setIntegrationByName({
        ...integrations,
      })
    );
  };
};

export const updateIntegrationEntry = (integrationName, status) => {
  return async (dispatch, getState) => {
    if (!integrationName) throw new Error("integration name is undefined");
    const state = getState();
    const user = state.user;
    let companyId = undefined;

    if (user && user.employer && user.employer.employerId) {
      companyId = user.employer.employerId;
    } else {
      throw new Error(
        "User's employer account ID not found. Cannot create new entry."
      );
    }

    const db = getFirestore();

    const integrationsRef = collection(db, "integrations");
    const integrationsQ = query(
      integrationsRef,
      where("integrationOwnerId", "==", companyId),
      where("integrationName", "==", integrationName)
    );

    // ensure that integration entry doesn't already exist
    const integrationQuerySnapshot = await getDocs(integrationsQ);

    if (integrationQuerySnapshot.empty) {
      throw new Error("Cannot find existing integration");
    }

    if (integrationQuerySnapshot.size !== 1) {
      throw new Error("Found multiple existing integrations");
    }

    const data = {
      ...status,
      lastUpdated: serverTimestamp(),
    };

    await setDoc(integrationQuerySnapshot.docs[0].ref, data, {
      merge: true,
    });
    // dispatch(
    //   integrationSlice.actions.setIntegrationByName({
    //     [integrationName]: data,
    //   })
    // );
  };
};

export const createNewIntegrationEntry = (integrationName) => {
  return async (dispatch, getState) => {
    if (!integrationName) throw new Error("integration name is  undefined");
    const state = getState();
    const user = state.user;
    let companyId = undefined;

    if (user && user.employer && user.employer.employerId) {
      companyId = user.employer.employerId;
    } else {
      throw new Error(
        "User's employer account ID not found. Cannot create new entry."
      );
    }

    const db = getFirestore();

    const integrationsRef = collection(db, "integrations");
    const integrationsQ = query(
      integrationsRef,
      where("integrationOwnerId", "==", companyId),
      where("integrationName", "==", integrationName)
    );

    // ensure that integration entry doesn't already exist
    const integrationQuerySnapshot = await getDocs(integrationsQ);

    if (!integrationQuerySnapshot.empty) {
      throw new Error(
        "Cannot create new integration as integration already exists"
      );
    }

    const data = {
      integrationOwnerId: companyId,
      integrationName: integrationName,
      onboardingGuide: {
        status: IntegrationStatus.OnboardingGuide.Read,
      },
      authorize: {
        status: IntegrationStatus.Authorize.NotStarted,
      },
      connect: {
        status: IntegrationStatus.Connect.NotStarted,
      },
      lastUpdated: serverTimestamp(),
      createdTimestamp: serverTimestamp(),
    };

    await addDoc(collection(db, "integrations"), data);
    dispatch(
      integrationSlice.actions.setIntegrationByName({
        [integrationName]: data,
      })
    );
  };
};

export const { setIntegrations, setIntegrationByName, setLoading } =
  integrationSlice.actions;

export default integrationSlice.reducer;
