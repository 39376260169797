import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import adminEmployeesReducer from "../features/admin/adminEmployeesSlice";
import adminEmployeesSurveyReducer from "../features/admin/adminEmployeesSurveySlice";
import employeeReducer from "../features/employee/employeeSlice";
import companyReducer from "../features/company/companySlice";
import integrationReducer from "../features/integrations/integrationSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    adminEmployees: adminEmployeesReducer,
    employee: employeeReducer,
    adminEmployeesSurvey: adminEmployeesSurveyReducer,
    company: companyReducer,
    integrations: integrationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: () => true,
      },
    }),
});

export default store;
