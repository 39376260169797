import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../app/firebase";
import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import {
  ACCOUNT_TYPE,
  loadUserProfileData,
  signInAlreadyLoggedInUser,
} from "../../features/user/userSlice";
import { useDispatch } from "react-redux";

const defaultContext = {
  currentUser: null,
};

export const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await getIdTokenResult(user);
        if (
          idTokenResult &&
          idTokenResult.claims &&
          idTokenResult.claims.accountType
        ) {
          user.accountType = idTokenResult.claims.accountType;
        } else {
          user.accountType = ACCOUNT_TYPE.EMPLOYEE; // default assumption is they are employee, least access principle
        }
      }
      await dispatch(await signInAlreadyLoggedInUser(user));
      await dispatch(await loadUserProfileData(user));
      setCurrentUser(user);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthValue() {
  return useContext(AuthContext);
}

export default AuthProvider;
