import React from "react";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "./src/assets/theme";

import store from "./src/app/store";
import AuthProvider from "./src/components/auth/AuthProvider";

import { createStandaloneToast } from "@chakra-ui/toast";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";

const { ToastContainer } = createStandaloneToast();

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  if (typeof window === "undefined") return <></>;

  return (
    <>
      <Provider store={store}>
        <QueryParamProvider adapter={ReachAdapter}>
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <>
                {element}
                <ToastContainer />
              </>
            </ChakraProvider>
          </AuthProvider>
        </QueryParamProvider>
      </Provider>
    </>
  );
};
