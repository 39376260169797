import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { getFirestore } from "../../app/firebase";

/**
 * The surveyData is a array of UID's and survey data that will indicate which employees survey this belongs to.
 * The data is loaded on request to ensure that correct permissions are applied before obtaining the data.
 *
 * initialState = {
 *   surveyData: {
 *      [UID]: {
 *        personalInformationQuestionnaireSurveyData: {},
 *    }
 *  }
 * }
 */
const initialState = {
  surveyData: {},
};

const adminEmployeesSurveySlice = createSlice({
  name: "adminEmployeesSurveySlice",
  initialState,
  reducers: {
    setEmployeesSurveyData(state, action) {
      LogDebug(
        setEmployeesSurveyData.name,
        "Initiated setting employees survey state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setLoading.name,
        "Initiated setting employee survey data in loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

/**
 * Expects a valid UID that is used to search for employees belonging to that
 * user.
 * @param {UID} uidToFindEmployeesBy
 * @returns
 */
export const fetchPersonalInformationQuestionnaireSurveyDataByUids = (uids) => {
  return async (dispatch) => {
    const db = getFirestore();

    const surveyData = {};

    const surveyRefDocs = [];
    for (const uid of uids) {
      const surveyRef = doc(
        db,
        `users/${uid}/survey/`,
        "employeePersonalInformationQuestionnaireSchema"
      );

      surveyRefDocs.push(getDoc(surveyRef));
    }

    await Promise.all(surveyRefDocs);

    if (surveyRefDocs.length !== uids.length) {
      throw new Error("Doc reference failed to create");
    }

    for (let idx = 0; idx < surveyRefDocs.length; ++idx) {
      const docSnap = await surveyRefDocs[idx];
      const uid = uids[idx];

      surveyData[uid] = {};
      if (docSnap.exists()) {
        surveyData[uid].personalInformationQuestionnaireSurveyData =
          docSnap.data();
      } else {
        surveyData[uid].personalInformationQuestionnaireSurveyData = {};
      }
    }

    dispatch(
      adminEmployeesSurveySlice.actions.setEmployeesSurveyData({
        surveyData: surveyData,
      })
    );
  };
};

export const { setEmployeesSurveyData, setLoading } =
  adminEmployeesSurveySlice.actions;

export default adminEmployeesSurveySlice.reducer;
