import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";

import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createStandaloneToast } from "@chakra-ui/toast";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFirestore } from "../../app/firebase";

const initialState = {
  email: "",
  firebaseUid: "",
  accountType: "",
  name: { first: "", last: "" },
  fullName: "",
  profilePictureAvailable: false,
  startDate: undefined,
  platformJoinDate: undefined,
  title: "",
  loggedIn: false,
  lastStateUpdate: new Date(),
  employer: { id: "", name: "", country: "", province: "" },
  assignedSurveys: [],
};

export const ACCOUNT_TYPE = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      LogDebug(
        setUser.name,
        "Initiated setting user state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        loggedIn: true,
        lastStateUpdate: new Date(),
      });
    },
    removeUser(state, action) {
      LogDebug(
        setUser.name,
        "Initiated removing user state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        loggedIn: false,
        lastStateUpdate: new Date(),
      });
    },
    addUserInformation(state, action) {
      LogDebug(
        addUserInformation.name,
        "Initiated setting user information after loading.",
        action.payload
      );
      Object.assign(state, { ...action.payload, lastStateUpdate: new Date() });
    },
  },
});

/**
 * Expects a user that is parsed manually for app consumption
 * @param {AuthUser} user
 * @returns
 */
export const signInAlreadyLoggedInUser = (user) => {
  if (!user) {
    return signOutUser();
  }

  return async (dispatch) => {
    await dispatch(
      userSlice.actions.setUser({
        email: user.email,
        firebaseUid: user.uid,
        accountType: user.accountType ?? ACCOUNT_TYPE.EMPLOYEE,
      })
    );
  };
};

export const signInUserWithEmailAndPassword = (
  email,
  password,
  rememberMe = false
) => {
  const { toast } = createStandaloneToast();
  return async (dispatch, getState) => {
    // check user is not logged in already
    const state = getState();
    const user = state.user;

    if (user.loggedIn) return;

    const auth = getAuth();
    setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    );

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      dispatch(
        userSlice.actions.setUser({
          email: userCredential.user.email,
          firebaseUid: userCredential.user.uid,
          accountType: userCredential.user.accountType ?? ACCOUNT_TYPE.EMPLOYEE,
        })
      );
      toast({
        title: "Logged in successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Wrong email or password. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      dispatch(
        userSlice.actions.removeUser(JSON.parse(JSON.stringify(initialState)))
      );
    }
  };
};

export const signOutUser = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    if (!user.loggedIn) return;

    const auth = getAuth();

    try {
      await signOut(auth);
      dispatch(
        userSlice.actions.removeUser(JSON.parse(JSON.stringify(initialState)))
      );
    } catch (e) {
      console.error(e);
    }
  };
};

export const getUserProfileImageDownloadUrl = async (uid) => {
  const storage = getStorage();
  const profileImageRef = ref(storage, `users/${uid}/userProfileImage`);

  try {
    const url = await getDownloadURL(profileImageRef);
    return url;
  } catch (e) {
    console.error("Cannot find profile image");
    console.error(e);
    return undefined;
  }
};

/**
 * Keep getUser and loadUserProfileData in synchronization
 */
export const getUser = async (firebaseUid) => {
  const db = getFirestore();
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("uid", "==", firebaseUid));

  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs[0].data();

  const employersRef = collection(db, "employers");
  const employerQ = query(
    employersRef,
    where("employerId", "==", data.employerId)
  );

  const employerQuerySnapshot = await getDocs(employerQ);

  if (employerQuerySnapshot.size != 1) return undefined;

  const employerData = employerQuerySnapshot.docs[0].data();

  return {
    employer: employerData,
    name: { first: data.firstName, last: data.lastName },
    fullName: `${data.firstName} ${data.lastName}`,
    title: data.title,
    platformJoinDate: data.createTimestamp.toDate(),
    startDate: data.startDate.toDate(),
    profilePictureAvailable: data.profilePictureAvailable ?? false,
    assignedSurveys: data.assignedSurveys ?? [],
    firebaseUid: firebaseUid,
  };
};

export const loadUserProfileData = () => {
  return async (dispatch, getState) => {
    const { toast } = createStandaloneToast();
    const state = getState();
    const user = state.user;

    const auth = getAuth();

    if (!auth.currentUser) return;

    const firebaseUid = user.firebaseUid;

    const db = getFirestore();
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "==", firebaseUid));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      await setDoc(doc(db, "users", firebaseUid), {
        createTimestamp: serverTimestamp(),
        email: user.email,
        uid: user.firebaseUid,
      });
    } else {
      // there should be one doc
      try {
        const data = querySnapshot.docs[0].data();

        const employersRef = collection(db, "employers");
        const employerQ = query(
          employersRef,
          where("employerId", "==", data.employerId)
        );

        const employerQuerySnapshot = await getDocs(employerQ);

        if (employerQuerySnapshot.empty) {
          toast({
            title: "Could not find employer details. Please contact support.",
            status: "error",
            isClosable: true,
          });
        } else {
          const employerData = employerQuerySnapshot.docs[0].data();

          await dispatch(
            userSlice.actions.addUserInformation({
              employer: employerData,
              name: { first: data.firstName, last: data.lastName },
              fullName: `${data.firstName} ${data.lastName}`,
              title: data.title,
              platformJoinDate: data.createTimestamp.toDate(),
              startDate: data.startDate.toDate(),
              profilePictureAvailable: data.profilePictureAvailable ?? false,
              assignedSurveys: data.assignedSurveys ?? [],
            })
          );
        }
      } catch (e) {
        toast({
          title:
            "User profile information could not be obtained. Please try again.",
          status: "error",
          isClosable: true,
        });
        console.error(e);
      }
    }
  };
};

export const saveUserProfileImage = (imageFile) => {
  return async (dispatch, getState) => {
    const storage = getStorage();
    const state = getState();
    const user = state.user;
    const { toast } = createStandaloneToast();

    if (!user.loggedIn) return;

    const profileImageRef = ref(
      storage,
      `users/${user.firebaseUid}/userProfileImage`
    );

    try {
      await uploadBytes(profileImageRef, imageFile);
      await dispatch(saveUserData({ profilePictureAvailable: true }));
      toast({
        title: `Profile image updated successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Profile image could not be updated. Please try again.",
        status: "error",
        isClosable: true,
      });
    }
  };
};

export const saveUserData = (data) => {
  return async (dispatch, getState) => {
    data = { ...data, lastUpdated: serverTimestamp() };
    const firebaseData = { ...data, lastUpdated: serverTimestamp() };
    const state = getState();
    const user = state.user;
    const db = getFirestore();

    const userRef = doc(db, "users", user.firebaseUid);

    let stateTransformedData = {};

    if (data.firstName) {
      stateTransformedData.name = {
        ...stateTransformedData.name,
        first: data.firstName,
      };

      delete data.firstName;
    }

    if (data.lastName) {
      stateTransformedData.name = {
        ...stateTransformedData.name,
        last: data.lastName,
      };
      delete data.lastName;
    }

    stateTransformedData = { ...stateTransformedData, ...data };

    await updateDoc(userRef, firebaseData);
    await dispatch(userSlice.actions.addUserInformation(stateTransformedData));
  };
};

export const { setLoading, setUser, addUserInformation } = userSlice.actions;
export default userSlice.reducer;
