const notStarted = "NotStarted";
const inProgress = "InProgress";
export const IntegrationStatus = {
  Authorize: {
    NotAuthorized: "NotAuthorized",
    Authorized: "Authorized",
    InProgress: inProgress,
    NotStarted: notStarted,
  },
  Connect: {
    Connected: "Connected",
    Disconnected: "Disconnected",
    InProgress: inProgress,
    NotStarted: notStarted,
  },
  OnboardingGuide: {
    Read: "Read",
    NotRead: "NotRead",
    InProgress: "InProgress",
  },
};
