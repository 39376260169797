import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

export const theme = extendTheme(
  {
    colors: {
      brand: {
        blue: "#4164e3",
        cadet: "#8998a8",
        dark: "#243156",
        gray: "#a0acb9",
        green: "#36c537",
        light: "#e9ebee",
        pure: "#fafafb",
        slate: "#77889a",
        white: "#fcfdfe",
        yellow: "#ed9b13",
      },
    },
    components: {
      Tabs: {
        baseStyle: {
          tab: {
            _focus: {
              boxShadow: "none",
            },
          },
        },
      },
    },
  },
  withProse()
);
