import { LogDebug } from "../../helpers/LogHelper";
import { createSlice } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  doc,
  serverTimestamp,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { addUserInformation } from "../user/userSlice";
import { getFirestore } from "../../app/firebase";

const initialState = {
  employers: [],
};

const companySlice = createSlice({
  name: "companySlice",
  initialState,
  reducers: {
    setCompanies(state, action) {
      LogDebug(
        setCompanies.name,
        "Initiated setting employees survey state after loading.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: true,
        loading: false,
      });
    },
    setLoading(state, action) {
      LogDebug(
        setLoading.name,
        "Initiated setting employee survey data in loading state.",
        action.payload
      );
      Object.assign(state, {
        ...action.payload,
        lastUpdated: new Date(),
        dataAvailable: false,
        loading: true,
      });
    },
  },
});

export const fetchAllAvalaibleEmployers = () => {
  return async (dispatch) => {
    const db = getFirestore();
    const employersCollectionQuery = collection(db, "employers");

    const employers = [];

    const snapshot = await getDocs(employersCollectionQuery);

    for (const document of snapshot.docs) {
      const data = document.data();
      employers.push(data);
    }

    dispatch(
      companySlice.actions.setCompanies({
        employers: employers,
      })
    );
  };
};

export const updateCompanyProfile = (companyId, data) => {
  return async (dispatch) => {
    data = { ...data, lastUpdated: serverTimestamp() };
    const db = getFirestore();

    const employersRef = collection(db, "employers");
    const employerQ = query(employersRef, where("employerId", "==", companyId));

    const employerQuerySnapshot = await getDocs(employerQ);

    if (
      !employerQuerySnapshot.empty &&
      employerQuerySnapshot.docs.length === 1
    ) {
      const employerRef = doc(
        db,
        "employers",
        employerQuerySnapshot.docs[0].id
      );
      await updateDoc(employerRef, { ...data, lastUpdated: serverTimestamp() });
    }

    if (data.name) {
      await dispatch(
        addUserInformation({
          employer: { ...data },
        })
      );
    }
  };
};

export const fetchEmployer = async (employerId) => {
  const db = getFirestore();
  const employersRef = collection(db, "employers");
  const employerQuery = query(
    employersRef,
    where("employerId", "==", employerId)
  );

  const employerQuerySnapshot = await getDocs(employerQuery);

  if (employerQuerySnapshot.empty) {
    return undefined;
  } else {
    const employerDoc = await employerQuerySnapshot.docs[0];
    const employerData = employerDoc.data();
    return { data: employerData, id: employerDoc.id };
  }
};

export const { setCompanies, setLoading } = companySlice.actions;

export default companySlice.reducer;
